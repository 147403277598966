import { Injectable } from '@angular/core';
import {
  HelperService,
  IMainMenuItem, LanguageService,
  PermissionService, RolePickerComponent,
  SidebarService,
  UserInfoService,
  UserTypeEnum,
} from 'c4p-portal-util';
import { KeycloakService } from 'keycloak-angular';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private keycloakService: KeycloakService,
    private permissionService: PermissionService,
    private userInfoService: UserInfoService,
    private sidebarService: SidebarService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private dateTimeAdapter: NgxMatDateAdapter<any>,
    private matCalendarDateAdapter: DateAdapter<Date>,
  ) {
    this.translate.onDefaultLangChange.subscribe(
      ({ lang }: LangChangeEvent) => {
        this.dateAdapter.setLocale(lang);
        this.dateTimeAdapter.setLocale(lang);
        this.matCalendarDateAdapter.setLocale(lang);
      },
    );
  }

  public async initializeUser(mainMenuItems: IMainMenuItem[]) {
    this.keycloakService.getKeycloakInstance();
    const tokenData = await this.keycloakService.getToken()
    const token = HelperService.parseJwt(tokenData);
    this.permissionService.initRolePermissions(token);
    if (
      token.user_type === UserTypeEnum.Client ||
      token.user_type === UserTypeEnum.ClientNetwork
    ) {
      // Mark that user is logged in Client Portal
      this.userInfoService.isClientPortal = true;
      this.permissionService.initUserPermissions(token);
      if (!this.permissionService.currentRole)
        this.permissionService.setClientRole(token.realm_access.roles);
      this.permissionService.initRolePermissions(tokenData).subscribe(() => {
        this.editSidebarMenu(token, mainMenuItems);
        this.userInfoService.initUserInfo(token);
      });

    } else {
      await this.userInfoService.setUpUserSettings(token.user_id);
      this.permissionService.initUserPermissions(token);
      if (this.permissionService.currentRole) {
        await this.setPermissionAndEmployee(token);
      }else {
        await this.roleDialog(token);
        const employee = await this.userInfoService.getEmployeeDetailAsync(token.user_id);
        this.translateService.setDefaultLang(employee.preferredLanguage ? employee.preferredLanguage : 'nl');
      }
    }
  }

  private async setPermissionAndEmployee(token) {
    this.permissionService.permissions = await this.permissionService.getRolePermissionsAsync(token);
    const employee = await this.userInfoService.getEmployeeDetailAsync(token.user_id);
    this.userInfoService.setEmployeeUser(employee);
  }

  private async roleDialog(token: any) {
    const dataDialog = await this.matDialog
      .open(RolePickerComponent, {
        data: null,
      })
      .afterClosed()
      .toPromise();

    if (dataDialog) {
      this.userInfoService.initUserInfo(token);
      await this.setPermissionAndEmployee(token);
    }

    location.reload();
  }



  private editSidebarMenu(token: any, mainMenuItems: IMainMenuItem[]) {
    //If there's only one carefile, remove Carefiles sidebar item
    if (token.user_type === UserTypeEnum.ClientNetwork) {
      this.userInfoService
        .getUserCarefiles(token.user_id)
        .subscribe((carefiles) => {
          if (carefiles.totalDocs < 2) {
            for (const sidebarItem of mainMenuItems) {
              sidebarItem.items = sidebarItem.items.filter(
                (it) => it.name !== 'general.labels.carefiles',
              );
              this.sidebarService.setSidebarItems(mainMenuItems);
            }
          }
        });
    } else if (token.user_type === UserTypeEnum.Client) {
      this.userInfoService
        .getClientCarefiles(token.user_id)
        .pipe(
          map((response: any) => {
            return response.docs;
          }),
        )
        .subscribe((careFiles: any[]) => {
          if (careFiles.length < 2) {
            for (const sidebarItem of mainMenuItems) {
              sidebarItem.items = sidebarItem.items.filter(
                (it) => it.name !== 'general.labels.carefiles',
              );
              this.sidebarService.setSidebarItems(mainMenuItems);
            }
          }
        });
    } else {
      this.sidebarService.setSidebarItems(mainMenuItems);
    }
  }
}
